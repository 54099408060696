/* autoprefixer grid: autoplace */
@import 'app/@theme/styles/styles.scss';
@import '~bootstrap/scss/bootstrap';
@import '~primeng/resources/primeng.css';
@import '~@seniorsistemas/primeng-theme/dist/theme.css';
@import '../src/app/@theme/styles/colors.scss';

$required: #e23f3f;

*,
input,
select,
textarea,
option,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

@media (min-width: 760px) {
  .modal-dialog {
    min-width: 730px !important;
    margin: 1.75rem auto;
  }
}

.nav-link {
  color: #999999;
}

.nav-link:hover {
  color: #999999;
}

input {
  outline: none;
}

::-webkit-scrollbar-thumb {
  border: solid 6px transparent;
  background-color: #d6d6d6;
  min-height: 60px;
  cursor: pointer;

  &:hover {
    background-color: #a1a1a1;
  }
}
::-webkit-scrollbar {
  background: #ffffff;
  height: 5px;
  width: 12px;
}

body {
  background: #ffffff;
  padding: 0;
  margin: 0;
}

.box-container {
  background: #ffffff;
  box-shadow: $shadow;
}

.dashboard-content {
  margin: 1.875rem 2.5rem 0 2.5rem;
}

.inner-content {
  font-size: $roboto;
  padding: 1.875rem 2.5rem;
}

.toast-container {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #ffffff;

  &.toast {
    margin: 2px !important;
    box-shadow: none !important;
    border-radius: 20px !important;
    background-color: #fff;
  }

  .toastr-error {
    background: #e64141;
  }

  .toastr-success {
    background: #00c89b;
  }

  .toast-title {
    color: #ffffff;
    font-weight: 700;
  }

  .toast-message {
    color: #ececec;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.enabled {
  opacity: 1 !important;
  pointer-events: visible !important;
}

.required {
  &::after {
    content: '*';
    color: $required;
    margin-left: 5px;
  }
}

.input-coupon {
  min-width: 60% !important;
  input {
    border-radius: 8px 0px 0px 8px !important;
  }
}

.button-coupon {
  margin-top: 28px;

  button {
    border-radius: 0px 8px 8px 0px !important;
  }
}

body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last,
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  color: #000 !important;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  color: #fff !important;
  border: 1px solid #00c89b !important;
  background: #00c89b !important;
}

body .ui-state-active,
body .ui-state-highlight {
  background-color: #00c89b;
}

body .ui-dropdown-panel li.ui-dropdown-item.ui-state-highlight:hover,
body .ui-tieredmenu li.ui-menuitem .ui-menuitem-link.ui-state-active:hover {
  background-color: #00c89d9c;
}

body .ui-accordion .ui-accordion-toggle-icon:before {
  content: '\f078';
}

body .ui-accordion .ui-state-active .ui-accordion-toggle-icon:before {
  content: '\f106';
}

body .ui-accordion .ui-accordion-header.ui-state-active a {
  color: #00c89b;
}

body .ui-accordion .ui-accordion-header.ui-state-active {
  background-color: rgba(#e5e5e5, 0.9);
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled):hover {
  background-color: rgba(#e5e5e5, 0.9);
}

body .ui-chkbox .ui-chkbox-box.ui-state-active{
  background-color: #00C89B !important;
  border-color: #00C89B !important;
}

body .ui-breadcrumb ul li .ui-menuitem-link{
  color: #999999;
}

body .ui-breadcrumb ul li .ui-menuitem-link:hover{
  color: $senior-logo;
}

