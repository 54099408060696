/** fonts */
$montserrat: "Montserrat", sans-serif;
$import-montserrat: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&display=swap");

$roboto: "Roboto", sans-serif;
$import-robot: url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

$roboto-condensed: "Roboto Condensed", sans-serif;
$import-roboto-condensed: url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

/** styles */
$shadow: 0 2px 6px #0000000a;
$text-shadow: 0 2px 6px #00000065;
$font: "Open Sans", sans-serif;
$transition: all cubic-bezier(0.16, 0.97, 0.58, 0.94) 250ms;
